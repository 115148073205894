import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    return (
      <section id="contact">
          <div className="row section-head">
          <div class="two columns header-col">

               <h1><span>Get In Touch.</span></h1>

            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4>Email: </h4>
    					   <p class="address">
    						   <span>himanshu.c.k.h@gmail.com</span>
    					   </p>
              </div>
            </aside>
          </div>
        </section>
        );
  }
}
